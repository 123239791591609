import React from 'react';

export default class CloseFullScreenIcon extends React.Component {
    render() {
        return (
            <svg 
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                width="28.391182"
                height="29.067274"
            >
                <g transform="translate(-702.24969,-717.00507)">
                    <path
                        d="m 29.1875,15 c -0.17728,0 -0.3125,0.08253 -0.3125,0.1875 l 0,9.65625 0,3.46875 0,0.3125 c 0,0.19944 0.08642,0.375 0.1875,0.375 l 12.625,0 c 0.101081,0 0.1875,-0.17556 0.1875,-0.375 l 0,-3.78125 C 41.875,24.64431 41.788581,24.5 41.6875,24.5 l -5.21875,0 5.84375,-5.84375 C 42.39222,18.57653 42.35977,18.391025 42.21875,18.25 l -2.6875,-2.6875 C 39.390225,15.421475 39.204722,15.389028 39.125,15.46875 l -6.25,6.25 0,-6.53125 C 32.875,15.082532 32.73978,15 32.5625,15 l -3.375,0 z"
                        transform="translate(688.28571,702.00507)"
                        fill="#000000"
                        fillOpacity={0.53333315}
                        stroke="none"
                    />
                    <path
                        d="m 717.97321,717.50507 c -0.1662,0 -0.3125,0.0466 -0.3125,0.125 l 0,9.6875 0,2.375 0,0.15625 c 0,0.011 0.0263,0.021 0.0312,0.0312 0.009,0.0251 0.0189,0.045 0.0312,0.0625 0.0117,0.009 0.0161,0.0241 0.0312,0.0312 0.005,0.004 0.0256,-0.003 0.0312,0 0.01,0.005 0.0207,0.0312 0.0312,0.0312 l 0.15625,0 2.40625,0 9.15625,0 c 0.0753,0 0.125,-0.1463 0.125,-0.3125 l 0,-2.375 c 0,-0.1662 -0.0497,-0.3125 -0.125,-0.3125 l -6.6875,0 6.71875,-6.75 c 0.0599,-0.0599 0.0238,-0.19498 -0.0937,-0.3125 l -1.6875,-1.6875 c -0.11752,-0.11752 -0.28388,-0.18487 -0.34375,-0.125 l -6.78125,6.8125 0,-7.3125 c 0,-0.0784 -0.11505,-0.125 -0.28125,-0.125 l -2.40625,0 z"
                        fill="#ffffff"
                        fillOpacity={1}
                        stroke="none"
                    />
                    <path
                        d="M 14.9375,30 C 14.836419,30 14.75,30.14431 14.75,30.34375 l 0,3.78125 c 0,0.19944 0.08642,0.375 0.1875,0.375 l 5.40625,0 L 14,40.84375 c -0.08247,0.08247 -0.01603,0.265225 0.125,0.40625 l 2.65625,2.65625 c 0.141025,0.141025 0.323779,0.207471 0.40625,0.125 l 6.5625,-6.5625 0,6.3125 C 23.75,43.890106 23.88522,44 24.0625,44 l 3.34375,0 C 27.58353,44 27.75,43.890106 27.75,43.78125 l 0,-9.65625 0,-3.78125 0,-0.15625 c 0,-0.03362 -0.03846,-0.06614 -0.0625,-0.09375 -0.02446,-0.0281 -0.05106,-0.04471 -0.09375,-0.0625 C 27.58054,30.02529 27.576651,30 27.5625,30 l -0.15625,0 -3.34375,0 -9.125,0 z"
                        transform="translate(688.28571,702.00507)"
                        fill="#000000"
                        fillOpacity={0.53333315}
                        stroke="none"
                    />
                    <path
                        d="m 703.66071,732.50507 c -0.0753,0 -0.125,0.11505 -0.125,0.28125 l 0,2.40625 c 0,0.1662 0.0497,0.3125 0.125,0.3125 l 6.65625,0 -7,7.03125 c -0.0599,0.0599 -0.0238,0.19498 0.0937,0.3125 l 1.71875,1.6875 c 0.11752,0.11752 0.25263,0.18487 0.3125,0.125 l 7.09375,-7.125 0,7.3125 c 0,0.0784 0.11505,0.15625 0.28125,0.15625 l 2.40625,0 c 0.1662,0 0.3125,-0.0779 0.3125,-0.15625 l 0,-9.65625 0,-2.40625 0,-0.15625 c 0,-0.0261 -0.0365,-0.0415 -0.0625,-0.0625 -0.009,-0.0209 -0.0194,-0.0173 -0.0312,-0.0312 -0.008,-0.004 -0.0229,0.003 -0.0312,0 -0.0131,-0.009 -0.0168,-0.0312 -0.0312,-0.0312 l -11.71875,0 z"
                        fill="#ffffff"
                        fillOpacity={1}
                        stroke="none"
                    />
                </g>
            </svg >
        )
    }
}