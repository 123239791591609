import React, { useEffect, useRef } from 'react';
import { Map, TileLayer, GeoJSON } from 'react-leaflet';
import { countriesGeoData2 } from '../data/countriesGeoData_2';
import axios from 'axios';
import { CovidDataContext } from '../App.js';
import L from 'leaflet';
import publicIp from 'react-public-ip';
import { CountryData, CountryMapLegend } from "./index.js";
import CloseFullscreenIcon from "../icons/CloseFullscreenIcon";
import OpenFullscreenIcon from "../icons/OpenFullscreenIcon";

export const ChoroplethMap = () => {
    const { state, dispatch } = React.useContext(CovidDataContext);
    const [hasLoadedCovidData, setHasLoadedCovidData] = React.useState(false);

    const [userIso, setUserIso] = React.useState({
        "loaded": false,
        "iso": null
    });
    
    const [mapPosition, setMapPosition] = React.useState([39.8283, -98.5795]); // init to USA
    const geoJson = useRef();
    const leafletCountryMap = useRef();
    const [grades, setGrades] = React.useState(null);
    const [activeCountryData, setActiveCountryData] = React.useState({
        "name": "",
        "totalCases": null,
        "totalDeaths": null
    });

    // get cloudflare's country iso header if available
    useEffect(() => {
        fetch("api/user/GetUsersCountryIsoFromHeader").then(response => response.json()).then(data => {
            setUserIso({
                loaded: true,
                iso: data.iso
            });
        }).catch(error => {
            console.log(error);
            setUserIso({
                loaded: true,
                iso: null
            })
        });

        // example for getting user's IP
        //publicIp.v4().then(res => {
        //    if (res) {
        //        setIp(res);
        //    }
        //});
    }, []);

    // use backup geolocation if Cloudflare iso header no available
    useEffect(() => {
        if (userIso.loaded && !userIso.iso) {
            //const url = window.location.protocol + "//ip-api.com/json/?fields=countryCode";
            //axios.get(url).then(response => {
                //console.log(response);
                setUserIso({
                    ...userIso,
                    iso: 'US'
                });
            //}).catch(error => {
            //    console.log(error);
            //});
        }
    }, [userIso]);

    useEffect(() => {
        // get latest country data on load
        axios.get("api/country/GetLatestCountryReport")
            .then(res => {
                dispatch({
                    type: "LATESTCOUNTRYDATA",
                    payload: res
                });
                //console.log(res);

                // now pair covid data with countries geo data
                countriesGeoData2.features.forEach(feature => {
                    const iso3 = feature.properties.iso_a3;
                    let covidData = null;

                    if (res.data && res.data.latestReports && typeof(res.data.latestReports) === "object") {
                        covidData = res.data.latestReports.filter(function (data) {
                            return data.iso3 === iso3;
                        });
                    }

                    // add new covid data points to country geo data
                    if (covidData && typeof (covidData) === "object" && covidData.length > 0) {
                        feature.properties.TotalCases = covidData[0].totalCases;
                        feature.properties.TotalDeaths = covidData[0].totalDeaths;
                    }
                    // no data available
                    else {
                        feature.properties.TotalCases = "N/A";
                        feature.properties.TotalDeaths = "N/A";
                    }

                    // set Total Cases per Population percentage
                    let casesPerPop = 0;
                    if(feature.properties.TotalCases 
                        && feature.properties.population 
                        && typeof(feature.properties.TotalCases) === "number" 
                        && typeof(feature.properties.population) === "number"
                        && feature.properties.population > 0)
                    {
                        casesPerPop = feature.properties.TotalCases/feature.properties.population;
                    }
                    feature.properties.CasesPerPop = casesPerPop;
                });

                setGrades(calculateGrades());

                //console.log(countriesGeoData);
                setHasLoadedCovidData(true); 
            });
    }, []);

    useEffect(() => {
        if (userIso.iso && hasLoadedCovidData) {
            // attempt to reset init country data to users country
            var item = countriesGeoData2.features.find(feature => {
                return feature.properties.iso_a2 === userIso.iso;
            });

            if (item) {
                // update map
                setMapPosition(item.properties.center);

                // update covid data context
                dispatch({
                    type: "CHANGEACTIVECOUNTRY",
                    payload: {
                        iso2: item.properties.iso_a2,
                        iso3: item.properties.iso_a3,
                        name: item.properties.name
                    }
                });
            }
        }
    }, [userIso, hasLoadedCovidData])

    // set data for active country
    useEffect(() => {
        if (state.activeCountry && hasLoadedCovidData) {
            // find associated data
            var item = countriesGeoData2.features.find(feature => {
                return feature.properties.iso_a2 === state.activeCountry.iso2;
            });

            //console.log(item);

            if (item) {
                setActiveCountryData({
                    name: item.properties.name,
                    totalCases: item.properties.TotalCases,
                    totalDeaths: item.properties.TotalDeaths
                }); 
                setMapPosition(item.properties.center);
            }
        }
    }, [state.activeCountry, hasLoadedCovidData])

    function calculateGrades(){
        const numGrades = 10;
        const min = 0;
        let max = 0;
        
        let CountryCasesPercentages = [];
        countriesGeoData2.features.forEach(c => {
            CountryCasesPercentages.push(c.properties.CasesPerPop);
        });

        const arrSort = CountryCasesPercentages.sort();
        const len = arrSort.length;
        //const mid = Math.ceil(len/2);
        //const median = len % 2 == 0 ? (arrSort[mid] + arrSort[mid - 1]) / 2 : arrSort[mid - 1];
        const arrSum = CountryCasesPercentages.reduce((sum, val) => (sum += val));
        const avg = arrSum/len;
        //console.log("median:", median, "avg:", avg);
        const increment = avg / (numGrades/2);

        let Grades = [min]
        let sum = min
        for(let i=1; i<numGrades-1;i++){
            sum += increment;
            Grades.push(sum)
        }
        //console.log("Grades:", Grades, "Max:", max);
        return Grades;
    }

    // add color to geojson boundaries
    function getColor(colorValue) {
        return colorValue > grades[9] ? '#641E16' :
        colorValue > grades[8] ? '#7B241C' :
        colorValue > grades[7] ? '#922B21' :
        colorValue > grades[6] ? '#A93226' :
        colorValue > grades[5] ? '#C0392B' :
        colorValue > grades[4] ? '#CD6155' :
        colorValue > grades[3] ? '#D98880' :
        colorValue > grades[2] ? '#E6B0AA' :
        colorValue > grades[1] ? '#F2D7D5' :
        colorValue > grades[0] ? '#F9EBEA' : '#FFFFFF';
    }

    function style(feature) {
        let colorValue = 0;
        if(feature.properties.TotalCases 
            && feature.properties.population 
            && typeof(feature.properties.TotalCases) === "number" 
            && typeof(feature.properties.population) === "number"
            && feature.properties.population > 0)
        {
            colorValue = feature.properties.TotalCases/feature.properties.population;
        }

        return {
            fillColor: getColor(colorValue),
            weight: 1,
            opacity: 1,
            color: 'white',
            fillOpacity: 0.75
        };
    }

    function highlightFeature(e) {
        var layer = e.target;

        layer.setStyle({
            weight: 2,
            color: '#FFF',
            dashArray: '',
            fillOpacity: 0.75
        });

        if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
            layer.bringToFront();
        }
    }

    function resetHighlight(e) {
        geoJson.current.leafletElement.resetStyle(e.target);
    }

    function showCountryStats(e) {
        //// center map over clicked feature
        //if (e.target.feature.properties.center) {
        //    leafletCountryMap.current.leafletElement.panTo(e.target.feature.properties.center);
        //}
        //// use center of country boundaries if center data not available
        //else {
        //    leafletCountryMap.current.leafletElement.panTo(e.target.getCenter());
        //}

        //console.log("clicked on new place: ", e.target.feature.properties.iso_a2);

        // update covid data context
        dispatch({
            type: "CHANGEACTIVECOUNTRY",
            payload: {
                iso2: e.target.feature.properties.iso_a2,
                iso3: e.target.feature.properties.iso_a3,
                name: e.target.feature.properties.name
            }
        });
    }

    function onEachFeature(feature, layer) {
        layer.on({
            mouseover: highlightFeature,
            mouseout: resetHighlight,
            click: showCountryStats
        });
    }

    return (
        hasLoadedCovidData && grades != null ?
            <div className="countries-map">
                <div className="heading-container">
                    <span className="country-map-title heading">Total Cases by Country {activeCountryData.TotalCases}</span>
                </div>            
                <Map
                    ref={leafletCountryMap}
                    id="leaflet-map"
                    center={mapPosition}
                    zoom={2}
                    worldCopyJump={true}
                    zoomControl={false}
                >
                    <TileLayer
                        attribution='Esri, Maxar, Earthstar Geographics, CNES/Airbus DS, USDA, USGS, AeroGRID, IGN, and the GIS User Community'
                        url='https://fly.maptiles.arcgis.com/arcgis/rest/services/World_Imagery_Firefly/MapServer/tile/{z}/{y}/{x}'
                    />
                    <GeoJSON ref={geoJson} data={countriesGeoData2} style={style} onEachFeature={onEachFeature} />
                    <CountryData displayData={activeCountryData} />
                    <CountryMapLegend grades={grades} getColor={getColor} />
                </Map>
            </div>
            : <div className="countries-map-loading">Loading...</div>
    );
}
export default ChoroplethMap;