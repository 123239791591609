import React from 'react';

export function SiteHeader() {
    return (
        <div className="header-container">
            <span className="site-header">TRACKCOVIDNINETEEN.COM</span>
            <div className="filter-layer"></div>
            <div className="image-layer"></div>
        </div>
    );
}
