import { BehaviorSubject } from 'rxjs';
import { handleResponse } from '../helpers';
import fetcher from '../helpers/fetch-wrapper';
import jwt_decode from "jwt-decode";

const currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentUser')));

export const authenticationService = {
    login,
    logout,
    getClaims,
    currentUser: currentUserSubject.asObservable(),
    get currentUserValue() { return currentUserSubject.value },
    isJWTExpired,
    getJWT
};

function login(username, password) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, password })
    };

    return fetch(`/api/user/authenticate`, requestOptions)
        .then(handleResponse)
        .then(user => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('currentUser', JSON.stringify(user));
            currentUserSubject.next(user);

            return user;
        });
}

function getJWT() {
    const user = localStorage.getItem('currentUser')
    const token = user ? JSON.parse(user).token : null;

    return token;
}

function isJWTExpired() {
    const token = getJWT();
    if(token){
        var decodedJWT = jwt_decode(getJWT());
        console.log(decodedJWT);

        // https://github.com/auth0/jwt-decode/issues/53
        var current_time = Date.now().valueOf() / 1000;
    
        return current_time > decodedJWT.exp
    }
    
    return true;
}

function getClaims() {
    const requestOptions = {
        method: 'GET',
    };

    return fetcher(`/api/user/claims`, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        })
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    currentUserSubject.next(null);
}