import { withLeaflet, MapControl } from "react-leaflet";
import L from "leaflet";

class CountryMapLegend extends MapControl {
    constructor(props, context) {
        super(props);
    }

    createLeafletElement(props) {
        const CountryMapLegend = L.Control.extend({
            onAdd: map => {
                //console.log(props);
                this.panelDiv = L.DomUtil.create("div", "country-legend");
                this.panelDiv.innerHTML += "<div class=\"legend-title\">Cases/Population</div>";
                for (var i = 0; i < props.grades.length; i++) {
                    this.panelDiv.innerHTML += `
                        <i style="background: ${props.getColor(props.grades[i])}"></i> 
                        ${"> " + (props.grades[i] * 100).toLocaleString(navigator.language, { minimumFractionDigits: 0 }) + "%"}<br>
                    `;
                }
                
                return this.panelDiv;
            }
        });
        return new CountryMapLegend({ position: "bottomleft" });
    }

    componentDidMount() {
        const { map } = this.props.leaflet;
        this.leafletElement.addTo(map);
    }
}

export default withLeaflet(CountryMapLegend);
