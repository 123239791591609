import { withLeaflet, MapControl } from "react-leaflet";
import L from "leaflet";

class CountryData extends MapControl {
    constructor(props, context) {
        super(props);
        props.leaflet.map.addEventListener("click", ev => {
            this.panelDiv.innerHTML = this.getDisplayString();
        });

        props.leaflet.map.addEventListener("moveend", ev => {
            this.panelDiv.innerHTML = this.getDisplayString();
        });
    }

    getDisplayString() {
        var totalCases = this.props.displayData.totalCases && typeof (this.props.displayData.totalCases) === 'number' ?
            this.props.displayData.totalCases.toLocaleString(navigator.language, { minimumFractionDigits: 0 })
            : this.props.displayData.totalCases;

        var totalDeaths = this.props.displayData.totalDeaths && typeof (this.props.displayData.totalDeaths) === 'number' ?
            this.props.displayData.totalDeaths.toLocaleString(navigator.language, { minimumFractionDigits: 0 })
            : this.props.displayData.totalDeaths;

        if (this.props.displayData.name)
            return `
                    <div class="country-name">${this.props.displayData.name}</div>
                    <div class="country-control-row"><div class="label">Total Cases:</div><div class="value">${totalCases}</div></div>
                    <div class="country-control-row"><div class="label">Total Deaths:</div><div class="value">${totalDeaths}</div></div>
            `;
        else
            return 'Click a country to view statistics';
    }

    createLeafletElement(props)
    {
        const CountryData = L.Control.extend({
            onAdd: map => {
                this.panelDiv = L.DomUtil.create("div", "country-info");
                this.panelDiv.innerHTML = this.getDisplayString();
                return this.panelDiv;
            }
        });
        return new CountryData({ position: "topright" });
    }

    componentDidMount() {
        const { map } = this.props.leaflet;
        this.leafletElement.addTo(map);
    }
}

export default withLeaflet(CountryData);
