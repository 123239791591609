import React, { useEffect } from 'react';
import axios from 'axios';
import { CovidDataContext } from '../App.js';

export const GlobalCasesOverview = () => {
    const { state, dispatch } = React.useContext(CovidDataContext);
    const [chartData, setChartData] = React.useState();

    useEffect(() => {
        if (!state.latestWorldData) {
            axios.get(`api/world/getLatestWorldReport`).then(res => {
                if (res.status === 200 && res.data && res.data.latestReport) {
                    dispatch({
                        type: "LATESTWORLDDATA",
                        payload: { "totalCases": res.data.latestReport.totalCases, "totalDeaths": res.data.latestReport.totalDeaths }
                    });
                }
            }).catch(error => {
                console.log(error);
            });
        }
    });

    return (
        state.latestWorldData ?
        <div className="global-overview heading">
            <div className="title-text">Global</div>
            <div className="content-container">
                <div className="total-cases-container">
                    <div className="count">{state.latestWorldData.totalCases.toLocaleString(navigator.language, { minimumFractionDigits: 0 })}</div>
                    <div className="text">Confirmed</div>
                </div>
                <div className="total-deaths-container">
                    <div className="count">{state.latestWorldData.totalDeaths.toLocaleString(navigator.language, { minimumFractionDigits: 0 })}</div>
                    <div className="text">Deaths</div>
                </div>
            </div>
        </div>
        : <></>
    );
}

export default GlobalCasesOverview;