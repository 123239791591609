import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { authenticationService } from '../services';

export const Admin = () => {
    const currentUser = authenticationService.currentUserValue;

    // redirect to home if sufficient privileges not present
    if (!currentUser || !currentUser.role || currentUser.role !== "admin") {
        return <Redirect to={{ pathname: '/'}} />
    }

    return (
        <div className="admin-container"> 
            <div>You have reached the admin page!</div>
            <a href={`/hangfire?access_token=${currentUser.token}`}>Hangfire Dashboard</a>
        </div>
    );
}

export default Admin;
