import React from 'react';

export default class OpenFullScreenIcon extends React.Component {
    render() {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                width="21.125"
                height="21.78125"
            >
                <g transform="translate(-700.84821,-718.56757)">
                    <path
                        d="m 20.875,16.5625 c -0.101081,0 -0.1875,0.14431 -0.1875,0.34375 l 0,3.78125 c 0,0.19944 0.08642,0.375 0.1875,0.375 l 5.125,0 -9.4375,9.4375 0,-5.96875 c 0,-0.104968 -0.13522,-0.1875 -0.3125,-0.1875 l -3.375,0 c -0.17728,0 -0.3125,0.08253 -0.3125,0.1875 l 0,13.125 0,0.3125 c 0,0.19944 0.08642,0.375 0.1875,0.375 l 12.625,0 c 0.101081,0 0.1875,-0.17556 0.1875,-0.375 l 0,-3.78125 c 0,-0.19944 -0.08642,-0.34375 -0.1875,-0.34375 l -5.8125,0 10.125,-10.09375 0,6.59375 c 0,0.108856 0.16647,0.21875 0.34375,0.21875 l 3.34375,0 c 0.17728,0 0.3125,-0.109894 0.3125,-0.21875 l 0,-9.65625 0,-3.78125 0,-0.15625 c 0,-0.01281 -0.02748,-0.01914 -0.03125,-0.03125 -0.0047,-0.01514 0.01,-0.04883 0,-0.0625 -0.01847,-0.03644 -0.03746,-0.04437 -0.0625,-0.0625 -0.0081,-0.005 -0.02232,0.0044 -0.03125,0 -0.01321,-0.006 -0.0171,-0.03125 -0.03125,-0.03125 l -0.15625,0 -3.34375,0 -9.15625,0 z"
                        transform="translate(688.28571,702.00507)"
                        fill="#000000"
                        fillOpacity={0.53333315}
                        stroke="none"
                    />
                    <path
                        d="m 709.62946,719.06757 c -0.0753,0 -0.15625,0.11505 -0.15625,0.28125 l 0,2.40625 c 0,0.1662 0.081,0.3125 0.15625,0.3125 l 6.375,0 -11.65625,11.65625 0,-6.75 c 0,-0.0784 -0.1463,-0.125 -0.3125,-0.125 l -2.40625,0 c -0.1662,0 -0.28125,0.0466 -0.28125,0.125 l 0,9.65625 0,2.40625 0,0.15625 c 0,0.0131 0.0243,0.0192 0.0312,0.0312 0.003,0.008 -0.003,0.0237 0,0.0312 0.0151,0.0169 0.0354,0.0185 0.0625,0.0312 0.0163,0.0151 0.0124,0.0625 0.0312,0.0625 l 11.75,0 c 0.0753,0 0.125,-0.1463 0.125,-0.3125 l 0,-2.40625 c 0,-0.1662 -0.0497,-0.28125 -0.125,-0.28125 l -7.28125,0 12.53125,-12.53125 0,7.59375 c 0,0.0784 0.1463,0.15625 0.3125,0.15625 l 2.40625,0 c 0.1662,0 0.28125,-0.0779 0.28125,-0.15625 l 0,-9.65625 0,-2.40625 0,-0.15625 c 0,-0.0151 -0.0221,-0.0176 -0.0312,-0.0312 -0.005,-0.008 0.008,-0.0238 0,-0.0312 -0.009,-0.0209 -0.0194,-0.0173 -0.0312,-0.0312 -0.008,-0.004 -0.0229,0.003 -0.0312,0 -0.0131,-0.009 -0.0168,-0.0312 -0.0312,-0.0312 l -0.15625,0 -2.40625,0 -9.15625,0 z"
                        fill="#ffffff"
                        fillOpacity={1}
                        stroke="none"
                    />
                </g>
            </svg >
        )
    }
}


