import React, { useEffect } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import { FetchData } from './components/FetchData';
import { Counter } from './components/Counter';
import axios from 'axios';

export const CovidDataContext = React.createContext();

const initialState = {
    latestCountryData: null,
    lastCountryDataPull: null,
    activeCountry: {
        iso2: "",
        iso3: "",
        name: ""
    },
    fullCountryReportsArray: [],
    latestWorldData: null
};

const reducer = (state, action) => {
    switch (action.type) {
        case "LATESTCOUNTRYDATA":
            var latestDate = null;
            try {
                // calculate lastest available data       
                if (action.payload.data.lastRun) {
                    latestDate = new Date(action.payload.data.lastRun);
                }

            } catch(ex){
                // todo
                console.log(ex);
            }

            return {
                ...state,
                latestCountryData: action.payload.data,
                lastCountryDataPull: latestDate
            };
        case "CHANGEACTIVECOUNTRY":
            return {
                ...state,
                activeCountry: {
                    iso2: action.payload.iso2,
                    iso3: action.payload.iso3,
                    name: action.payload.name
                }
            };
        case "STOREFULLCOUNTRYREPORT":
            if (action.payload && typeof (action.payload) === "object") {
                try {
                    // check if object already exists
                    if (state.fullCountryReportsArray.find(item => item.Iso === action.payload.Iso)) {
                        return {
                            ...state
                        };
                    } else {
                        return {
                            ...state,
                            fullCountryReportsArray: [...state.fullCountryReportsArray, action.payload]
                        };
                    } 
                } catch (ex) {
                    console.log("Error adding full country reports to CovidDataContext", ex);
                    return {
                        ...state
                    };
                }
            }
            break;
        case "LATESTWORLDDATA":
            if (action.payload && typeof (action.payload) === "object") {
                return {
                    ...state,
                    latestWorldData: action.payload
                }
        }
        default:
            return state;
    }
};

function App() {
    const [state, dispatch] = React.useReducer(reducer, initialState);

    // get country data for active country
    // run whenever active country changes
    useEffect(() => {
        if (state.activeCountry) {
            //check if we need to get new data
            var existingData = state.fullCountryReportsArray.find(item => item.Iso === state.activeCountry.iso2);

            if (!existingData) {
                if (state.activeCountry.iso2) {
                    // get data for active country
                    axios.get(`api/country/GetAllReportsForCountryIso?Iso=${state.activeCountry.iso2}`).then(res => {
                        if (res.status === 200 && res.data && res.data.countryReportList) {
                            dispatch({
                                type: "STOREFULLCOUNTRYREPORT",
                                payload: { "Iso": state.activeCountry.iso2, "data": res.data.countryReportList }
                            });
                        }
                    }).catch(error => {
                        console.log(error);
                    });
                }
            } 
        }
    }, [state.activeCountry]);

    return (
        <CovidDataContext.Provider
            value={{
                state,
                dispatch
            }}>
            <Layout>
                <Route exact path='/' component={Home} />
                <Route path='/counter' component={Counter} />
                <Route path='/fetchdata' component={FetchData} />
            </Layout>
        </CovidDataContext.Provider>
    );
}

export default App;
