import React, { useEffect } from 'react';
import {
    ComposedChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip,
    Legend, ResponsiveContainer,
} from 'recharts';
import { CovidDataContext } from '../App.js';

export const NewCountryDeathsChart = () => {
    const { state, dispatch } = React.useContext(CovidDataContext);
    const [chartData, setChartData] = React.useState();

    // run whenever active country changes
    useEffect(() => {
        if (state.activeCountry) {
            //check if we need to update chart data
            if (
                (chartData && typeof (chartData) === "object" && chartData.length > 0 && state.activeCountry.iso2 !== chartData[0].Iso)
                || !chartData
                || (typeof (chartData) === "object" && chartData.length == 0)
            ) {
                var data = state.fullCountryReportsArray.find(item => item.Iso === state.activeCountry.iso2);
                if (data) {
                    setChartData(data.data);
                } else {
                    setChartData(null);
                }
            }
        }
    }, [state.activeCountry, state.fullCountryReportsArray]);

    return (
        <div className="country-chart">
            <div className="chart-container">
                <div className="country-chart-title heading">Daily - New Deaths ({state.activeCountry.name})</div>
                <ResponsiveContainer width='100%' height={400}>
                    <ComposedChart
                        width={1000}
                        height={350}
                        data={chartData}
                        margin={{
                            top: 20, right: 20, bottom: 20, left: 20,
                        }}
                    >
                        <CartesianGrid stroke="#f5f5f5" />
                        <XAxis dataKey="formattedDate" angle={-25} textAnchor="end"/>
                        <YAxis />
                        <Tooltip />
                        {/*<Legend />*/}
                        <Bar dataKey="newDeaths" name="New Deaths" barSize={100} fill="#331815" />
                    </ComposedChart>
                </ResponsiveContainer>
            </div>
        </div>
    );
}

export default NewCountryDeathsChart;