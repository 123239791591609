import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { authenticationService } from '../services';

export const Login = (props) => {
    const [username, setUsername] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [error, setError] = React.useState(false);

    var onSubmitClick = function (e) {
        authenticationService.login(username, password)
            .then(
                user => {
                    const { from } = props.location.state || { from: { pathname: "/" } };
                    props.history.push(from);
                },
                error => {
                    console.log("error logging in");
                    setError(true);
                }
            );
    };

    return (
        <div className="login-page">
            <div className="login-container">
                <h3>Sign In</h3>

                <div className="form-group">
                    <label>Username</label>
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Enter username"
                        id="username"
                        value={username}
                        onChange={event => setUsername(event.target.value)}
                />
                </div>

                <div className="form-group">
                    <label>Password</label>
                    <input
                        id="password"
                        value={password}
                        onChange={event => setPassword(event.target.value)}
                        type="password"
                        className="form-control"
                        placeholder="Enter password" />
                </div>

                <button onClick={onSubmitClick} className="btn btn-primary btn-block">Submit</button>
                {error && <div className="login-error">Username or password incorrect!</div>}
            </div>
        </div>
    );
}

export default Login;