import './index.css';
import './main.scss';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import App from './App';
import { Admin, Login } from './components/index';
import { authenticationService } from './services';
//import registerServiceWorker from './registerServiceWorker';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

/* Using this component requires the user to have a valid JWT (must be logged in)
*  **NOTE: this doesn't check role yet. any logged in user will pass
*/
const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={(props) => (
      (authenticationService.isJWTExpired() === false && authenticationService.currentUserValue)
        ? <Component {...props} />
        : <Redirect to='/login' />
    )} />
  )


ReactDOM.render(
    <Router>
        <Switch>
            <Route exact path="/" component={App} />
            <PrivateRoute exact path="/admin" component={Admin} />
            <Route exact path="/login" component={Login} />
        </Switch>
    </Router>,
  rootElement);


//registerServiceWorker();
