import React, { Component } from 'react';
import { ChoroplethMap } from './index.js'
import { NewCountryCasesChart, NewCountryDeathsChart, CountryRankChart, GlobalCasesOverview } from './index.js';

export class Home extends Component {
    displayName = Home.name

    render() {
        return (
            <div className="home-container">
                <GlobalCasesOverview />
                <div className="quadrant">
                    <ChoroplethMap />
                    <CountryRankChart />
                    <NewCountryCasesChart />
                    <NewCountryDeathsChart />
                </div>
            </div>
        );
    }
}
