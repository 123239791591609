import React, { Component } from 'react';
import { Footer, SiteHeader } from './index.js';

export class Layout extends Component {
    displayName = Layout.name

    render() {
        return (
            <div className="react-root">
                <SiteHeader />
                {this.props.children}
                <Footer />
            </div>
        );
    }
}
