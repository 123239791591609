/* This helper includes the jwt in the header on requests */

import { authenticationService } from "../services";

function updateOptions(options) {
    const update = { ...options };
    const token = authenticationService.getJWT;

    if (token) {
      update.headers = {
        ...update.headers,
        Authorization: `Bearer ${token}`,
      };
    }
    return update;
  }
  
  export default function fetcher(url, options) {
    return fetch(url, updateOptions(options));
  }