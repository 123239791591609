import React from 'react';
import { CovidDataContext } from '../App';

export function Footer() {
    const covidDataContext = React.useContext(CovidDataContext);
    let lastCountryDataPull = covidDataContext && covidDataContext.state && covidDataContext.state.lastCountryDataPull ?
        covidDataContext.state.lastCountryDataPull.toString()
        : "Unkown";

    return (
        <div className="footer-container">
            <div>Data source: <a target="_blank" rel="noopener noreferrer" href="https://ourworldindata.org/covid-cases">Our World in Data</a></div>
            <div>Last Data Pull: {lastCountryDataPull}</div>
            <div className="disclaimer">Disclaimer - All content on trackcovidnineteen.com is for information only. It is not intended to be a substitute for professional medical advice and should not be relied on as health or personal advice.</div>
            <div>Chris Uphaus | 2023</div>
        </div>
    );
}
