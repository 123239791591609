import React, { useEffect } from 'react';
import {
    ComposedChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip,
    Legend, ResponsiveContainer, Rectangle
} from 'recharts';
import { CovidDataContext } from '../App.js';
import axios from 'axios';

export const CountryRankChart = () => {
    const { state, dispatch } = React.useContext(CovidDataContext);
    const [chartData, setChartData] = React.useState();

    // build chart data
    useEffect(() => {
        if (state.latestCountryData && state.latestCountryData.latestReports) {
            //console.log(state.latestCountryData.latestReports);

            // order by greatest total cases desc and take top 10
            setChartData(state.latestCountryData.latestReports.sort(function (a, b) {
                return b.totalCases - a.totalCases;
            }).slice(0,10));
        }
    }, [state.latestCountryData])

    // run whenever active country changes
    useEffect(() => {
        if (state.activeCountry) {
            //console.log(state.activeCountryIso);

        }
    }, [state.activeCountry]);

    var onBarClick = function (e) {
        //console.log(e);

        // update covid data context
        dispatch({
            type: "CHANGEACTIVECOUNTRY",
            payload: {
                iso2: e.iso,
                iso3: e.iso3,
                name: e.countryName
            }
        });
    };

    const CustomBar = (props) => {
        //add outline if active country
        var strokeWidth = "0";
        if (props.iso === state.activeCountry.iso2) {
            strokeWidth = "2";
        }
        //console.log(props);
        //use explicit fill here, or use the additional css class and make a css selector to update fill there
        return <Rectangle {...props} stroke="#000000" strokeWidth={strokeWidth} className="recharts-bar-rectangle" />
    };

    return (
        <div className="country-rank-chart">
            <div className="chart-container">
                <div className="country-rank-chart-title heading">Top Ten Countries by Total Cases</div>
                <ResponsiveContainer width='100%' height={400}>
                    <ComposedChart
                        className="chart"
                        width={1000}
                        height={350}
                        data={chartData}
                        margin={{
                            top: 20, right: 20, bottom: 20, left: 20,
                        }}
                        layout="vertical"
                    >
                        <CartesianGrid stroke="#f5f5f5" />
                        <XAxis type="number" />
                        <YAxis dataKey="countryName" type="category"/>
                        <Tooltip />
                        <Legend />
                        <Bar
                            dataKey="totalCases"
                            name="Total Cases"
                            barSize={100}
                            fill="#CD6155"
                            onClick={onBarClick}
                            shape={CustomBar}
                        />
                    </ComposedChart>
                </ResponsiveContainer>
            </div>
        </div>
    );
}

export default CountryRankChart;